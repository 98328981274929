import { SimplePaletteColorOptions, ThemeOptions } from "@mui/material";

import palette from "./palette";

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: "h4",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: (palette.error as SimplePaletteColorOptions)?.main,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        height: "1em",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },
  },
};

export default components;
